export const updateCouponAmount = (state, action, decrease) => {
  state.couponList = state.couponList.map(item => {
    if (
      item.type === action.name &&
      !!state.userParticipationItems[action.name]
    ) {
      if (decrease) {
        if (!item.isUsingCoupon) {
          item.coupon -= 1;
          item.isUsingCoupon = true;
          item.hasCoupon = item.coupon >= 0;
        } 
      } else {
        item.coupon += 1;
        item.isUsingCoupon = false;
        item.hasCoupon = true;
      } 
    }
    return item;
  });
};