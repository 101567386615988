import * as types from './types';

export function smsLoginSendCode(params, onComplete) {
  return {
    type: types.SMS_LOGIN_SEND_CODE_REQUESTED,
    params,
    onComplete
  };
}
export function smsLoginVerificationCode(params, onComplete) {
  return {
    type: types.SMS_LOGIN_VERIFICATION_CODE_REQUESTED,
    params,
    onComplete
  };
}
export function loginFacebook(params, onComplete) {
  return {
    type: types.CURRENT_USER_LOGIN_FACEBOOK_REQUESTED,
    params,
    onComplete
  };
}
export function forceLoginByAdmin(params, onComplete) {
  return {
    type: types.ADMIN_FORCE_LOGIN_REQUESTED,
    params,
    onComplete
  };
}

export function forceLoginByUser(params, onComplete) {
  return {
    type: types.USER_FORCE_LOGIN_REQUESTED,
    params,
    onComplete
  };
}

export function logoutFacebook(params, onComplete) {
  return {
    type: types.CURRENT_USER_LOGOUT_FACEBOOK_REQUESTED,
    params,
    onComplete
  };
}

export function getFacebook(params, onComplete) {
  return {
    type: types.CURRENT_USER_LOGIN_FACEBOOK_INFO_REQUESTED,
    params,
    onComplete
  };
}

export function homescreen(params, onComplete) {
  return {
    type: types.HOME_SCREEN_REQUESTED,
    params,
    onComplete
  };
}

export function clearHomescreen(params, onComplete) {
  return {
    type: types.CLEAR_HOMESCREEN,
    params,
    onComplete
  };
}
export function getMatchDetail(params, onComplete) {
  return {
    type: types.CURRENT_USER_GET_MATCH_DATA_REQUESTED,
    params,
    onComplete
  };
}

export function getMatchProfileDetail(params, onComplete) {
  return {
    type: types.GET_MATCH_PROFILE_DETAIL_REQUESTED,
    params,
    onComplete
  };
}

export function getOpenDates(params, onComplete) {
  return {
    type: types.GET_OPEN_DATES_REQUESTED,
    params,
    onComplete
  };
}

export function getOpenDateStatus(params, onComplete) {
  return {
    type: types.GET_OPEN_DATES_STATUS_REQUESTED,
    params,
    onComplete
  };
}

export function getAwaitingParticipantDatingDays(params, onComplete) {
  return {
    type: types.GET_AWAITING_PARTICIPANT_DATING_DAYS_REQUESTED,
    params,
    onComplete
  };
}

export function requestParticipate(params, onComplete) {
  return {
    type: types.REQUEST_PARTICIPATE_REQUESTED,
    params,
    onComplete
  };
}

export function getChangeCostPlan(params, onComplete) {
  return {
    type: types.CURRENT_USER_CHANGE_COST_PLAN_REQUESTED,
    params,
    onComplete
  };
}

export function getCancelChangeCostPlan(params, onComplete) {
  return {
    type: types.CURRENT_USER_CANCEL_COST_PLAN_REQUESTED,
    params,
    onComplete
  };
}

export function resetMatchDetail() {
  return {
    type: types.CURRENT_USER_RESET_MATCH_DATA
  };
}

export function getPopups(params, onComplete) {
  return {
    type: types.POPUPS_REQUESTED,
    params,
    onComplete
  };
}

export function deletePopup(params, onComplete) {
  return {
    type: types.DELETE_POPUP_REQUESTED,
    params,
    onComplete
  };
}

export function partnerReflectedByCouponPopupShown() {
  return {
    type: types.PARTNER_REFLECTED_COUPON_POPUP_SHOWN
  };
}

export function userProfile(params, onComplete) {
  return {
    type: types.USER_PROFILE_REQUESTED,
    params,
    onComplete
  };
}

export function requestMyPageInfo(params, onComplete) {
  return {
    type: types.GET_MY_PAGE_INFO_REQUESTED,
    params,
    onComplete
  };
}

export function requestParticipateCommitOption(params, onComplete) {
  return {
    type: types.PARTICIPATE_COMMIT_OPTION_REQUEST,
    params,
    onComplete
  };
}

export function requestUserGlobalInfo(params, onComplete) {
  return {
    type: types.GET_USER_GLOBAL_INFO_REQUESTED,
    params,
    onComplete
  };
}
export function requestValidateDate(params, onComplete) {
  return {
    type: types.VALIDATE_SELECTING_REQUESTED,
    params,
    onComplete
  };
}

export function useCoupon(params, onComplete) {
  return {
    type: types.USE_COUPON_REQUESTED,
    params,
    onComplete
  };
}

export function reActivationReApprove(params, onComplete) {
  return {
    type: types.REACTIVATION_RE_APPROVE_REQUEST,
    params,
    onComplete
  };
}
export function reRegister(params, onComplete) {
  return {
    type: types.RE_REGISTER_REQUEST,
    params,
    onComplete
  };
}

export function cancelDate(params, onComplete) {
  return {
    type: types.CANCEL_DATE_REQUESTED,
    params,
    onComplete
  };
}

export function getCancelDatingOptions(params, onComplete) {
  return {
    type: types.GET_CANCEL_DATING_OPTIONS_REQUESTED,
    params,
    onComplete
  };
}

export function getCancelSampleDatingOptions(params, onComplete) {
  return {
    type: types.GET_CANCEL_SAMPLE_DATING_OPTIONS_REQUESTED,
    params,
    onComplete
  };
}

export function updateEmail(params, onComplete) {
  return {
    type: types.UPDATE_EMAIL_REQUESTED,
    params,
    onComplete
  };
}

export function stopReceiveNotifications(params, onComplete) {
  return {
    type: types.STOP_RECEIVE_NOTIFICATIONS_REQUESTED,
    params,
    onComplete
  };
}

export function getStopReceiveNotifications(params, onComplete) {
  return {
    type: types.GET_STOP_NOTIFICATION_CATEGORIES_REQUESTED,
    params,
    onComplete
  };
}

export function cancelForRematch(params, onComplete) {
  return {
    type: types.CANCEL_FOR_REMATCH_REQUESTED,
    params,
    onComplete
  };
}

export function cancelDateByPartner(params, onComplete) {
  return {
    type: types.CANCEL_DATE_BY_PARTNER_REQUESTED,
    params,
    onComplete
  };
}

export function participateRematch(params, onComplete) {
  return {
    type: types.PARTICIPATE_REMATCH_REQUESTED,
    params,
    onComplete
  };
}

export function chatRoom(params, onComplete) {
  return {
    type: types.CHAT_ROOM_REQUESTED,
    params,
    onComplete
  };
}

export function chatHistory(params, onComplete) {
  return {
    type: types.CHAT_HISTORY_REQUESTED,
    params,
    onComplete
  };
}

export function updateCursor(params, onComplete) {
  return {
    type: types.UPDATE_CURSOR_REQUESTED,
    params,
    onComplete
  };
}

export function getCafe(params, onComplete) {
  return {
    type: types.GET_CAFE_REQUESTED,
    params,
    onComplete
  };
}

export function getChatMessages(params, onComplete) {
  return {
    type: types.GET_CHAT_MESSAGES_REQUESTED,
    params,
    onComplete
  };
}

export function clearChatMessages() {
  return {
    type: types.CLEAR_CHAT_MESSAGES
  };
}

export function cancelParticipate(params, onComplete) {
  return {
    type: types.CANCEL_PARTICIPATE_REQUESTED,
    params,
    onComplete
  };
}

export function cancelSampleParticipant(params, onComplete) {
  return {
    type: types.CANCEL_SAMPLE_PARTICIPANT_REQUESTED,
    params,
    onComplete
  };
}

export function getTwilioToken(params, onComplete) {
  return {
    type: types.GET_TWILIO_TOKEN_REQUESTED,
    params,
    onComplete
  };
}

export function updateUserParticipationDates(dates) {
  return {
    type: types.UPDATE_USER_PARTICIPATION_DATES,
    dates
  };
}

export function showMaintain() {
  return {
    type: types.SHOW_MAINTAIN
  };
}

export function showErrorPopup(action) {
  return {
    type: types.UPDATE_GLOBAL_ERROR_POPUP,
    action: action
  };
}

export function resetErrorPopup() {
  return {
    type: types.RESET_GLOBAL_ERROR_POPUP
  };
}

export function showLoading() {
  return {
    type: types.SHOW_LOADING
  };
}

export function hideLoading() {
  return {
    type: types.HIDE_LOADING
  };
}
export function showTooltip() {
  return {
    type: types.SHOW_TOOLTIPS
  };
}

export function hideTooltip() {
  return {
    type: types.HIDE_TOOLTIPS
  };
}

export function showError() {
  return {
    type: types.SHOW_ERROR
  };
}

export function hideError() {
  return {
    type: types.HIDE_ERROR
  };
}

export function setParticipation(params, onComplete) {
  return {
    type: types.SET_PARTICIPATION_REQUESTED,
    params,
    onComplete
  };
}

export function validateDeactivateProfile(onComplete) {
  return {
    type: types.VALIDATE_DEACTIVATE_REQUESTED,
    onComplete
  };
}

export function requestDeactiveProfile(params, onComplete) {
  return {
    type: types.DEACTIVE_PROFILE_REQUESTED,
    params,
    onComplete
  };
}

export function requestCancelCommitOption(params, onComplete) {
  return {
    type: types.CANCEL_COMMIT_OPTION_REQUEST,
    params,
    onComplete
  };
}

export function openDrawer() {
  return {
    type: types.OPEN_DRAWER
  };
}

export function closeDrawer() {
  return {
    type: types.CLOSE_DRAWER
  };
}

export function requestCancelProfile(params, onComplete) {
  return {
    type: types.CANCEL_PROFILE_REQUESTED,
    params,
    onComplete
  };
}

export function requestSaveUserFeedback(params, onComplete) {
  return {
    type: types.SAVE_USER_FEEDBACK_REQUESTED,
    params,
    onComplete
  };
}

export function checkSendFeedback(params, onComplete) {
  return {
    type: types.CHECK_SEND_FEEDBACK_REQUESTED,
    params,
    onComplete
  };
}

export function requestAddRequestChangeTime(params, onComplete) {
  return {
    type: types.ADD_REQUEST_TIME_CHANGE_REQUESTED,
    params,
    onComplete
  };
}

export function requestUpdateRequestChangeTime(params, onComplete) {
  return {
    type: types.UPDATE_REQUEST_TIME_CHANGE_REQUESTED,
    params,
    onComplete
  };
}

export function requestUserFeedbackDetails(params, onComplete) {
  return {
    type: types.USER_FEEDBACK_DETAILS_REQUESTED,
    params,
    onComplete
  };
}

export function requestInviteFriendsData(params, onComplete) {
  return {
    type: types.INVITE_FRIENDS_DATA_REQUESTED,
    params,
    onComplete
  };
}

export function setNewInfoUserGlobalWhenApiGetNotCall(params, data) {
  return {
    type: types.SET_USER_INFO_WHEN_API_GET_NOT_CALL,
    params,
    data
  };
}

export function requestMatchProfileInfo(params, onComplete) {
  return {
    type: types.MATCH_PROFILE_INFO_REQUEST,
    params,
    onComplete
  };
}

export function requestCouponList(params, onComplete) {
  return {
    type: types.COUPON_LIST_REQUEST,
    params,
    onComplete
  };
}

export function requestGetCouponPackages(params, onComplete) {
  return {
    type: types.GET_COUPON_PACKAGE_REQUESTED,
    params,
    onComplete
  };
}

export function requestPurchaseCouponPackage(params, onComplete) {
  return {
    type: types.PURCHASE_COUPON_REQUESTED,
    params,
    onComplete
  };
}

export function requestExchangeCoupon(params, onComplete) {
  return {
    type: types.EXCHANGE_COUPON_REQUESTED,
    params,
    onComplete
  };
}

export function applyCoupon(params, onComplete) {
  return {
    type: types.APPLY_COUPON_REQUESTED,
    params,
    onComplete
  };
}
export function retrieveCoupons(params, onComplete) {
  return {
    type: types.RETRIEVE_COUPON_REQUESTED,
    params,
    onComplete
  };
}

export function decrementCoupon() {
  return {
    type: types.COUPON_LIST_DECREMENT
  };
}

export function resetCouponList() {
  return {
    type: types.COUPON_LIST_RESET
  };
}

export function requestRematch(params, onComplete) {
  return {
    type: types.REMATCH_REQUESTED,
    params,
    onComplete
  };
}

export function requestEvaluationInfo(params, onComplete) {
  return {
    type: types.EVALUATION_INFO_REQUEST,
    params,
    onComplete
  };
}

export function requestEvaluationList(params, onComplete) {
  return {
    type: types.EVALUATION_LIST_REQUEST,
    params,
    onComplete
  };
}

//upload user image
export function requestUploadUserImage(params, onComplete) {
  return {
    type: types.STORE_IMAGE_REQUESTED,
    params,
    onComplete
  };
}
//REGISTRATION FORM
//GET DATA
export function requestRegistrationFormGetStepOne(onComplete) {
  return {
    type: types.REGISTRATION_FORM_GET_STEP_ONE_REQUESTED,
    onComplete
  };
}
export function requestRegistrationFormGetStepTwo(onComplete) {
  return {
    type: types.REGISTRATION_FORM_GET_STEP_TWO_REQUESTED,
    onComplete
  };
}
export function requestRegistrationFormGetStepThree(onComplete) {
  return {
    type: types.REGISTRATION_FORM_GET_STEP_THREE_REQUESTED,
    onComplete
  };
}
export function requestRegistrationFormGetStepFour(onComplete) {
  return {
    type: types.REGISTRATION_FORM_GET_STEP_FOUR_REQUESTED,
    onComplete
  };
}
export function requestRegistrationFormGetStepFive(onComplete) {
  return {
    type: types.REGISTRATION_FORM_GET_STEP_FIVE_REQUESTED,
    onComplete
  };
}
export function requestRegistrationFormGetStepSix(onComplete) {
  return {
    type: types.REGISTRATION_FORM_GET_STEP_SIX_REQUESTED,
    onComplete
  };
}
export function requestRegistrationFormGetStepSeven(onComplete) {
  return {
    type: types.REGISTRATION_FORM_GET_STEP_SEVEN_REQUESTED,
    onComplete
  };
}
export function requestRegistrationFormGetStepSevenPointFive(onComplete) {
  return {
    type: types.REGISTRATION_FORM_GET_STEP_SEVEN_FIVE_REQUESTED,
    onComplete
  };
}
export function requestRegistrationFormGetStepEight(onComplete) {
  return {
    type: types.REGISTRATION_FORM_GET_STEP_EIGHT_REQUESTED,
    onComplete
  };
}

export function requestRegistrationFormGetStepOnePointFive(onComplete) {
  return {
    type: types.REGISTRATION_FORM_GET_STEP_ONE_POINT_FIVE_REQUESTED,
    onComplete
  };
}

//2nd form
export function requestRegistrationFormGetStepOne2nd(onComplete) {
  return {
    type: types.SECOND_REGISTRATION_FORM_GET_STEP_ONE_REQUESTED,
    onComplete
  };
}
export function requestRegistrationFormGetStepTwo2nd(onComplete) {
  return {
    type: types.SECOND_REGISTRATION_FORM_GET_STEP_TWO_REQUESTED,
    onComplete
  };
}
export function requestRegistrationFormGetStepThree2nd(onComplete) {
  return {
    type: types.SECOND_REGISTRATION_FORM_GET_STEP_THREE_REQUESTED,
    onComplete
  };
}
export function requestRegistrationFormGetStepFour2nd(onComplete) {
  return {
    type: types.SECOND_REGISTRATION_FORM_GET_STEP_FOUR_REQUESTED,
    onComplete
  };
}
export function requestRegistrationFormGetStepFive2nd(onComplete) {
  return {
    type: types.SECOND_REGISTRATION_FORM_GET_STEP_FIVE_REQUESTED,
    onComplete
  };
}
export function requestRegistrationFormGetStepSix2nd(onComplete) {
  return {
    type: types.SECOND_REGISTRATION_FORM_GET_STEP_SIX_REQUESTED,
    onComplete
  };
}

export function requestRegistrationFormGetStepSixPointFive2nd(onComplete) {
  return {
    type: types.SECOND_REGISTRATION_FORM_GET_STEP_SIX_FIVE_REQUESTED,
    onComplete
  };
}

export function requestRegistrationFormGetStepSeven2nd(onComplete) {
  return {
    type: types.SECOND_REGISTRATION_FORM_GET_STEP_SEVEN_REQUESTED,
    onComplete
  };
}

// POST DATA
export function requestRegistrationFormPostStepOne(params, onComplete) {
  return {
    type: types.REGISTRATION_FORM_POST_STEP_ONE_REQUESTED,
    params,
    onComplete
  };
}
export function requestRegistrationFormPostStepTwo(params, onComplete) {
  return {
    type: types.REGISTRATION_FORM_POST_STEP_TWO_REQUESTED,
    params,
    onComplete
  };
}
export function requestRegistrationFormPostStepThree(params, onComplete) {
  return {
    type: types.REGISTRATION_FORM_POST_STEP_THREE_REQUESTED,
    params,
    onComplete
  };
}
export function requestRegistrationFormPostStepFour(params, onComplete) {
  return {
    type: types.REGISTRATION_FORM_POST_STEP_FOUR_REQUESTED,
    params,
    onComplete
  };
}
export function requestRegistrationFormPostStepFive(params, onComplete) {
  return {
    type: types.REGISTRATION_FORM_POST_STEP_FIVE_REQUESTED,
    params,
    onComplete
  };
}
export function requestRegistrationFormPostStepSix(params, onComplete) {
  return {
    type: types.REGISTRATION_FORM_POST_STEP_SIX_REQUESTED,
    params,
    onComplete
  };
}
export function requestRegistrationFormPostStepSeven(params, onComplete) {
  return {
    type: types.REGISTRATION_FORM_POST_STEP_SEVEN_REQUESTED,
    params,
    onComplete
  };
}
export function requestRegistrationFormPostStepSevenFive(params, onComplete) {
  return {
    type: types.REGISTRATION_FORM_POST_STEP_SEVEN_FIVE_REQUESTED,
    params,
    onComplete
  };
}
export function requestRegistrationFormPostStepEight(params, onComplete) {
  return {
    type: types.REGISTRATION_FORM_POST_STEP_EIGHT_REQUESTED,
    params,
    onComplete
  };
}
export function requestRegistrationFormPostStepOnePointFive(
  params,
  onComplete
) {
  return {
    type: types.REGISTRATION_FORM_POST_STEP_ONE_POINT_FIVE_REQUESTED,
    params,
    onComplete
  };
}

export function requestRegistrationFormPostSpecificStepZero(
  params,
  onComplete
) {
  return {
    type: types.REGISTRATION_FORM_POST_SPECIFIC_STEP_ZERO_REQUESTED,
    params,
    onComplete
  };
}

//2nd form
export function requestRegistrationFormPostStepOne2nd(params, onComplete) {
  return {
    type: types.SECOND_REGISTRATION_FORM_POST_STEP_ONE_REQUESTED,
    params,
    onComplete
  };
}
export function requestRegistrationFormPostStepTwo2nd(params, onComplete) {
  return {
    type: types.SECOND_REGISTRATION_FORM_POST_STEP_TWO_REQUESTED,
    params,
    onComplete
  };
}
export function requestRegistrationFormPostStepThree2nd(params, onComplete) {
  return {
    type: types.SECOND_REGISTRATION_FORM_POST_STEP_THREE_REQUESTED,
    params,
    onComplete
  };
}
export function requestRegistrationFormPostStepFour2nd(params, onComplete) {
  return {
    type: types.SECOND_REGISTRATION_FORM_POST_STEP_FOUR_REQUESTED,
    params,
    onComplete
  };
}
export function requestRegistrationFormPostStepFive2nd(params, onComplete) {
  return {
    type: types.SECOND_REGISTRATION_FORM_POST_STEP_FIVE_REQUESTED,
    params,
    onComplete
  };
}
export function requestRegistrationFormPostStepSix2nd(params, onComplete) {
  return {
    type: types.SECOND_REGISTRATION_FORM_POST_STEP_SIX_REQUESTED,
    params,
    onComplete
  };
}
export function requestRegistrationFormPostStepSixPointFive2nd(
  params,
  onComplete
) {
  return {
    type: types.SECOND_REGISTRATION_FORM_POST_STEP_SIX_FIVE_REQUESTED,
    params,
    onComplete
  };
}
export function requestRegistrationFormPostStepSeven2nd(params, onComplete) {
  return {
    type: types.SECOND_REGISTRATION_FORM_POST_STEP_SEVEN_REQUESTED,
    params,
    onComplete
  };
}
export function getPlanSubscription(params, onComplete) {
  return {
    type: types.GET_PLAN_SUBSCRIPTION_REQUESTED,
    params,
    onComplete
  };
}
export function getPlan(params, onComplete) {
  return {
    type: types.GET_PLAN_REQUESTED,
    params,
    onComplete
  };
}
export function updatePlan(params, onComplete) {
  return {
    type: types.UPDATE_PLAN_REQUESTED,
    params,
    onComplete
  };
}

export function updateProfile(params, onComplete) {
  return {
    type: types.UPDATE_PROFILE_REQUESTED,
    params,
    onComplete
  };
}

export function getPreferencesInfo(params, onComplete) {
  return {
    type: types.GET_PREFERENCES_INFO_REQUESTED,
    params,
    onComplete
  };
}

export function updatePreferences(params, onComplete) {
  return {
    type: types.UPDATE_PREFERENCES_REQUESTED,
    params,
    onComplete
  };
}

export function requestRegistrationFormPostStepEight2nd(params, onComplete) {
  return {
    type: types.SECOND_REGISTRATION_FORM_POST_STEP_EIGHT_REQUESTED,
    params,
    onComplete
  };
}

export function updatePrefectureAndArea(params, onComplete) {
  return {
    type: types.UPDATE_PREFECTURE_AND_AREA_REQUESTED,
    params,
    onComplete
  };
}

export function requestCheckConditionChangePrefectureAndArea(
  params,
  onComplete
) {
  return {
    type: types.CONDITION_CHANGE_PREFECTURE_AND_AREA_REQUESTED,
    params,
    onComplete
  };
}

export function requestRegistrationFormGetStepEight2nd(onComplete) {
  return {
    type: types.SECOND_REGISTRATION_FORM_GET_STEP_EIGHT_REQUESTED,
    onComplete
  };
}
export function getCards(params, onComplete) {
  return {
    type: types.GET_CARDS_REQUESTED,
    params,
    onComplete
  };
}
export function createCard(params, onComplete) {
  return {
    type: types.CREATE_CARD_REQUESTED,
    params,
    onComplete
  };
}

export function setDefaultCard(params, onComplete) {
  return {
    type: types.SET_DEFAULT_CARD_REQUESTED,
    params,
    onComplete
  };
}

export function getCurrentServerTime(params, onComplete) {
  return {
    type: types.GET_CURRENT_SERVER_TIME_REQUESTED,
    params,
    onComplete
  };
}

export function getDatingDayAbleToChat(params, onComplete) {
  return {
    type: types.GET_DATING_DAY_ABLE_TO_CHAT_REQUESTED,
    params,
    onComplete
  };
}

export function accessChatRoom(params, onComplete) {
  return {
    type: types.ACCESS_CHAT_ROOM_REQUESTED,
    params,
    onComplete
  };
}

export function onUpdateDatingStatus(params, onComplete) {
  return {
    type: types.UPDATE_DATING_STATUS_REQUESTED,
    params,
    onComplete
  };
}

export function onUpdateMulDatingStatus(params, onComplete) {
  return {
    type: types.UPDATE_MULTIPLE_DATING_STATUS_REQUESTED,
    params,
    onComplete
  };
}

export function updateTempCancelTrial(params, onComplete) {
  return {
    type: types.UPDATE_TEMP_CANCEL_TRIAL_REQUESTED,
    params,
    onComplete
  };
}

export function requestUserInfoFacebookPixel(onComplete) {
  return {
    type: types.GET_USER_INFO_FB_PIXEL_REQUESTED,
    onComplete
  };
}

export function requestSendExceptionNotice(params, onComplete) {
  return {
    type: types.SEND_EXCEPTION_NOTICE_REQUESTED,
    params,
    onComplete
  };
}

export function validateBeforeUpdatePlan(params, onComplete) {
  return {
    type: types.VALIDATE_BEFORE_UPDATE_PLAN_REQUESTED,
    params,
    onComplete
  };
}

export function requestSubscriptionPause(params, onComplete) {
  return {
    type: types.SUBSCRIPTION_PAUSED_REQUEST,
    params,
    onComplete
  };
}

export function requestCancelSubscriptionPause(params, onComplete) {
  return {
    type: types.CANCEL_SUBSCRIPTION_PAUSED_REQUEST,
    params,
    onComplete
  };
}

export function getSubscriptionPauseInfoForCancel(onComplete) {
  return {
    type: types.GET_SUBSCRIPTION_PAUSE_INFO_FOR_CANCEL_REQUESTED,
    onComplete
  };
}

export function continueSubscriptionPause(onComplete) {
  return {
    type: types.CONTINUE_SUBSCRIPTION_PAUSED,
    onComplete
  };
}

export function setLoggedInWhenUserNot401() {
  return { type: types.CURRENT_USER_SUCCESS_NOT_401 };
}

export function requestUpdateUserMotivatedDating(params, onComplete) {
  return {
    type: types.UPDATE_USER_MOTIVATED_DATING_REQUESTED,
    params,
    onComplete
  };
}

export function requestSaveFeedbackCommitOption(params, onComplete) {
  return {
    type: types.SAVE_USER_FEEDBACK_COMMIT_OPTION_REQUESTED,
    params,
    onComplete
  };
}
export function getScheduledPlan(params, onComplete) {
  return {
    type: types.GET_SCHEDULED_PLAN_REQUESTED,
    params,
    onComplete
  };
}

export function requestAvailableFeedbackFlexQuestion(params, onComplete) {
  return {
    type: types.GET_AVAILABLE_FEEDBACK_FLEX_QUESTION_REQUESTED,
    params,
    onComplete
  };
}

export function setWhiteMode(params) {
  return {
    type: types.SET_WHITE_MODE,
    params
  };
}

export function getUserNeedToUpdateNewPreferences(params, onComplete) {
  return {
    type: types.USER_NEED_TO_UPDATE_NEW_PREFERENCES_REQUESTED,
    params,
    onComplete
  };
}

export function requestUpdateReadForNoticePopup(params, onComplete) {
  return {
    type: types.UPDATE_READ_STATUS_FOR_NOTICE_POPUP_REQUESTED,
    params,
    onComplete
  };
}

export function requestGetNoticePopup(params, onComplete) {
  return {
    type: types.GET_NOTICE_POPUP_REQUESTED,
    params,
    onComplete
  };
}

export function setNativeMode(params) {
  return {
    type: types.SET_NATIVE_MODE,
    params
  };
}

export function hideNeedToUpdateNewPreferencesPopup() {
  return {
    type: types.HIDE_NEED_TO_UPDATE_NEW_PREFERENCES_POPUP
  };
}

//USER CERTIFICATION
export function getUserCertification(params, onComplete) {
  return {
    type: types.USER_CERTIFICATION_REQUESTED,
    params,
    onComplete
  };
}

export function requestStoreImageUserCertification(params, onComplete) {
  return {
    type: types.STORE_IMAGE_USER_CERTIFICATION_REQUESTED,
    params,
    onComplete
  };
}

export function requestUpdateUserCertification(params, onComplete) {
  return {
    type: types.UPDATE_USER_CERTIFICATION_REQUESTED,
    params,
    onComplete
  };
}

export function requestStoreBannerFunctionData(params, onComplete) {
  return {
    type: types.STORE_BANNER_FUNCTION_REQUEST,
    params,
    onComplete
  };
}

export function requestChangeShowNewChatDesign(params, onComplete) {
  return {
    type: types.CHANGE_SHOW_NEW_CHAT_DESIGN_REQUESTED,
    params,
    onComplete
  };
}

export function requestAvailableFeedbackFixedQuestion(params, onComplete) {
  return {
    type: types.GET_AVAILABLE_FIXED_QUESTION_REQUESTED,
    params,
    onComplete
  };
}

export function requestCancelDeactiveAppOptions(onComplete) {
  return {
    type: types.GET_CANCEL_DEACTIVE_APP_OPTIONS_REQUESTED,
    onComplete
  };
}

export function requestFirstLoginAfterApproved(params, onComplete) {
  return {
    type: types.REQUEST_FIRST_LOGIN_AFTER_APPROVED_REQUESTED,
    params,
    onComplete
  };
}

export function getBannerSlideData(params, onComplete) {
  return {
    type: types.GET_BANNER_SLIDE_DATA,
    params,
    onComplete
  };
}

export function getStepUrl(params, onComplete) {
  return {
    type: types.REGISTRATION_FORM_GET_STEP_REQUESTED,
    params,
    onComplete
  };
}

//user rank
export function getUserRankInfo(params, onComplete) {
  return {
    type: types.USER_RANK_INFO_REQUESTED,
    params,
    onComplete
  };
}

export function requestUpRank(params, onComplete) {
  return {
    type: types.UP_RANK_REQUESTED,
    params,
    onComplete
  };
}

export function requestRemainRank(params, onComplete) {
  return {
    type: types.REMAIN_RANK_REQUESTED,
    params,
    onComplete
  };
}

export function requestApprovedOrRejectedRank(params, onComplete) {
  return {
    type: types.APPROVEED_OR_REJECTED_PROMOTE_RANK_REQUESTED,
    params,
    onComplete
  };
}

export function getEmailReservation(params, onComplete) {
  return {
    type: types.GET_EMAIL_RESERVATION_REQUESTED,
    params,
    onComplete
  }
}

export function clearActiveSubscriptionPausePopup() {
  return { type: types.CLEAR_ACTIVE_SUBSCRIPTION_PAUSE_POPUP };
}
// FACE TYPE
export function getFaceType(hideLoading, onComplete) {
  return {
    type: types.GET_FACE_TYPE_REQUESTED,
    hideLoading,
    onComplete,
  }
}

export function updateFaceType(params, onComplete) {
  return {
    type: types.UPDATE_FACE_TYPE_REQUESTED,
    params,
    onComplete
  };
}

export function updateFaceTypeStepOne(params, onComplete) {
  return {
    type: types.UPDATE_FACE_TYPE_ONE_REQUESTED,
    params,
    onComplete
  };
}

export function changeSampleParticipant(params, onComplete) {
  return {
    type: types.CHANGE_SAMPLE_PARTICIPATION_REQUESTED,
    params,
    onComplete
  }
}

export function getUserParticipationItems(params, onComplete) {
  return {
    type: types.USER_PARTICIPATION_ITEMS_REQUESTED,
    params,
    onComplete
  }
}

export function updateUserParticipationItemsFE(name, value, isInitData = true) {
  return {
    type: types.UPDATE_USER_PARTICIPATION_ITEMS,
    name,
    value,
    isInitData
  }
}

export function deleteUserParticipationItems(name) {
  return {
    type: types.DELETE_USER_PARTICIPATION_ITEM,
    name
  }
}

export function updateCouponList(selectedItems) {
  return {
    type: types.UPDATE_COUPON_LIST,
    selectedItems
  }
}

export function clearLastestParticipationItemsData() {
  return {
    type: types.CLEAR_LASTEST_PARTICIPATION_ITEMS_DATA,
  }
}

export function updateUserParticipationItemsStatus(params, onComplete) {
  return {
    type: types.UPDATE_USER_PARTICIPATION_ITEMS_STATUS_REQUESTED,
    params,
    onComplete
  }
}

export function purchaseDatingItems(params, onComplete) {
  return {
    type: types.PURCHASE_DATING_ITEMS_REQUESTED,
    params,
    onComplete
  }
}

export function getUserOwnedParticipationItems(params, onComplete) {
  return {
    type: types.USER_OWNED_PARTICIPATION_ITEMS_REQUESTED,
    params,
    onComplete
  }
}

export function getUserTrialInfo(params, onComplete) {
  return {
    type: types.GET_USER_TRIAL_INFO_REQUESTED,
    params,
    onComplete
  };
}

export function clearPersistRoot() {
  return {
    type: types.CURRENT_USER_LOGOUT_FACEBOOK_SUCCEEDED
  };
}
export function getMotivateImages(params, onComplete) {
  return {
    type: types.GET_MOTIVATE_IMAGES_REQUESTED,
    params,
    onComplete
  };
}
