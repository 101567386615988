import { cloneDeep } from 'lodash';
import * as types from '../actions/types';
import { path } from 'ramda';
import { updateCouponAmount } from '@redux/utils';

const INITIAL_LASTEST_USER_OPTIONS = {
  age: null,
  body_type: null,
  dating_time: null,
  face_preferences: null,
  dating_area: null,
  height: null,
};

const INITIAL = {
  data: null,
  participationItemsData: null,
  userParticipationItems: null,
  participationItemList: null,
  needToPurchaseItems: null,
  needToPurchaseItemsPrice: null,
  userOwnedParticipationItems: null,
  participationDetail: null,
  appliedItems: null,
  isError: false,
  lastestUserOptions: INITIAL_LASTEST_USER_OPTIONS,
  isInit: false,
  priceList: [],
  couponList: []
};

const userParticipationItemsReducer = (state = INITIAL, action) => {
  switch (action.type) {
    case types.USER_PARTICIPATION_ITEMS_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.USER_PARTICIPATION_ITEMS_SUCCEEDED: {
      const participationDetail = path(
        ['data', 'data', 'participationDetail'],
        action
      );
      const participationItemsData = path(
        ['data', 'data', 'participationItems'],
        action
      );
      const originalUserParticipationItems = path(
        ['data', 'data', 'userItems'],
        action
      );
      const userParticipationItemsForEditing = cloneDeep(
        originalUserParticipationItems
      );

      const priceList = Object.keys(participationItemsData).map(key => ({
        id: participationItemsData[key].item_id,
        price: participationItemsData[key].price,
        type: participationItemsData[key].type
      }));
      const couponList = Object.keys(participationItemsData).map(key => ({
        id: participationItemsData[key].item_id,
        coupon: state.isInit
          ? state.couponList.find(
              item => item.id === participationItemsData[key].item_id
            ).coupon
          : originalUserParticipationItems[key]
          ? originalUserParticipationItems[key].remainItem
          : 0,
        type: participationItemsData[key].type,
        isUsingCoupon: !state.isInit
          ? participationItemsData[key].is_applied_items
          : state.couponList.find(
              item => item.id === participationItemsData[key].item_id
            ).isUsingCoupon,
        hasCoupon: !state.isInit
          ? participationItemsData[key].is_applied_items ||
            !!originalUserParticipationItems[key]
          : state.couponList.find(
              item => item.id === participationItemsData[key].item_id
            ).hasCoupon
      }));
      Object.keys(participationItemsData).forEach(key => {
        if (participationItemsData[key].is_applied_items) {
          if (userParticipationItemsForEditing.hasOwnProperty(key)) {
            userParticipationItemsForEditing[key].remainItem += 1;
          } else {
            userParticipationItemsForEditing[key] = {
              ...participationItemsData[key],
              remainItem: 1
            };
          }
        }
      });
      const userParticipationItems = participationDetail.is_editing
        ? userParticipationItemsForEditing
        : originalUserParticipationItems;

      return {
        ...state,
        participationItemsData,
        userParticipationItems,
        participationItemList: path(['data', 'data', 'itemList'], action),
        needToPurchaseItems: path(['data', 'data', 'purchaseItems'], action),
        needToPurchaseItemsPrice: path(['data', 'data', 'totalPrice'], action),
        participationDetail,
        appliedItems: path(['data', 'data', 'appliedItems'], action),
        data: action.data.data,
        isInit: true,
        error: null,
        priceList,
        couponList
      };
    }
    case types.USER_PARTICIPATION_ITEMS_FAILED:
      return {
        ...state,
        data: null,
        isError: true,
        error: action.error
      };
    case types.UPDATE_USER_PARTICIPATION_ITEMS_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.UPDATE_USER_PARTICIPATION_ITEMS_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };
    case types.USER_OWNED_PARTICIPATION_ITEMS_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.USER_OWNED_PARTICIPATION_ITEMS_SUCCEEDED:
      return {
        ...state,
        userOwnedParticipationItems: action.data.data,
        error: null
      };
    case types.USER_OWNED_PARTICIPATION_ITEMS_FAILED:
      return {
        ...state,
        data: null,
        isError: true,
        error: action.error
      };
    case types.UPDATE_USER_PARTICIPATION_ITEMS: {
      !action.isInitData && updateCouponAmount(state, action, true);
      const tempLastestUserOptions = { ...state.lastestUserOptions };
      tempLastestUserOptions[action.name] = action.value;
      return {
        ...state,
        lastestUserOptions: tempLastestUserOptions
      };
    }
    case types.DELETE_USER_PARTICIPATION_ITEM: {
      updateCouponAmount(state, action, false);
      const tempLastestUserOptions = { ...state.lastestUserOptions };
      tempLastestUserOptions[action.name] = null;
      return {
        ...state,
        lastestUserOptions: tempLastestUserOptions
      };
    }
    case types.UPDATE_COUPON_LIST: {
      state.couponList = state.couponList.map(item => {
        if (
          action.selectedItems.find(
            selectedItem => selectedItem.type === item.type
          )
        ) {
          item.coupon = 0;
          item.isUsingCoupon = true;
          item.hasCoupon = true;
        }
        return item;
      });
      return {
        ...state
      };
    }
    case types.CLEAR_LASTEST_PARTICIPATION_ITEMS_DATA:
      return {
        ...state,
        lastestUserOptions: INITIAL_LASTEST_USER_OPTIONS,
        isInit: false
      };
    default:
      return state;
  }
};

export default userParticipationItemsReducer;
